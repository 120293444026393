<template lang="pug">
	v-app
		navHeader(interface_name='agency_list')
		v-main.maincont
			v-container
				v-row(justify='center')
					v-col.mx-4(cols='12', md='6', sm='8', lg='3', v-if='getAccesses.admin && getAccesses.admin.length')
						v-card.card(tile, color='primary', :loading="isLoading")
							v-card-title.title-container
								h3(style='color: #fff') Админ
							.access-container
								div(v-for='(item,i) of getAccesses.admin')
									v-btn.but(
										outlined,
										tile,
										style='color: #fff; width: 100%; margin-bottom: 0.7em; margin-top: 0.7em',
										v-if='item.types && item.types.length === 1',
										@click='Enter(item.id, "root", item.types[0])'
									) {{ item.name }}
									.groupIntiti(style='width: 100%; margin-bottom: 0.7em; margin-top: 0.7em', v-else)
										p.headerGroup {{ item.name }}
										v-btn.firstletter.but(
											outlined,
											tile,
											v-for='(typesname,index) of item.types',
											:key='index',
											@click='Enter(item.id, "root", item.types[index])'
										) {{ getTranslate(item.types[index]) }}
					v-col.mx-4(cols='12', md='6', sm='8', lg='3', v-if='getAccesses.agency && getAccesses.agency.length')
						v-card.card(tile, color='primary', :loading="isLoading")
							v-card-title.title-container
								h3(style='color: #fff') Агентство
							.access-container
								div(v-for='(item,i) of getAccesses.agency')
									v-btn.but(
										outlined,
										tile,
										style='color: #fff; width: 100%; margin-bottom: 0.7em; margin-top: 0.7em',
										v-if='item.types && item.types.length === 1',
										@click='Enter(item.id, "agency", item.types[0])'
									) {{ item.name }}
									.groupIntiti(style='width: 100%; margin-bottom: 0.7em; margin-top: 0.7em', v-else)
										p.headerGroup {{ item.name }}
										v-btn.firstletter.but(
											outlined,
											tile,
											v-for='(typesname,index) of item.types',
											:key='index',
											@click='Enter(item.id, "agency", item.types[index])'
										) {{ getTranslate(item.types[index]) }}
					v-col.mx-4(cols='12', md='6', sm='8', lg='3', v-if='getAccesses.account && getAccesses.account.length && !getAccesses.admin.length')
						v-card.card(tile, color='primary', :loading="isLoading")
							v-card-title.title-container
								h3(style='color: #fff') Рекламодатель
							.access-container
								div(v-for='(item,i) of getAccesses.account')
									v-btn.but(
										outlined,
										tile,
										style='color: #fff; width: 100%; margin-bottom: 0.7em; margin-top: 0.7em',
										v-if='item.types && item.types.length === 1',
										@click='Enter(item.id, "radar", item.types[0])'
									) {{ item.name }}
									.group-container(v-else)
										p.headerGroup {{ item.name }}
										v-btn.firstletter.but(
											outlined,
											tile,
											v-for='(typesname,index) of item.types',
											:key='index',
											@click='Enter(item.id, "radar", item.types[index])'
										) {{ getTranslate(item.types[index]) }}
</template>

<script>
import navHeader from '../components/nav/header';
import {mapActions, mapGetters} from 'vuex'
import {USER_INFO} from '@/store/const/user'
import {NEW_MODE} from '@/store/const/auth'

export default {
	name: 'agency_list',
	components: {
		navHeader,
	},
	data: function () {
		return {
			drawer: '',
			arrayOfObjectRoles: [
				{value: 'account', translate: 'аккаунтский'},
				{value: 'admin', translate: 'админ'},
				{value: 'agency', translate: 'агентский'},
				{value: 'agencylite', translate: 'агентский Self-Service'},
				{value: 'dmp', translate: 'DMP'},
				{value: 'guest', translate: 'гостевой'},
				{value: 'lite', translate: 'аккаунтский Self-Service'},
				{value: 'report', translate: 'Report'},
			],
			selectGroup: '',
			editUser: false,
			roleCabinets: [],
			agencies: [],
			accounts: [],
			reports: [],
			self: '',
			admin: [],
			users: [],
			dialogFormVisible: false,
			dialogUsersVisible: false,
			roles: [
				{
					types: '',
				},
			],
			userRoles: [
				{
					types: '',
				},
			],
			usersReportsCabs: [],
		};
	},
	head: {
		title: 'RADAR-OOH: Домашняя страница',
	},
	computed: {
		...mapGetters('Auth', ['getAccesses']),
		...mapGetters(['loading']),
		isLoading() {
			return this.loading(NEW_MODE) === 'loading' || this.loading(USER_INFO) === 'loading'
		},
	},
	async mounted() {
		await this.getUserInfo();
		if (this.isError(USER_INFO)) {
			this.$notify.warning({
				title: 'Ошибка',
				text: 'Не удалось получить данные',
			});
		}
	},
	methods: {
		...mapActions('User', {
			getUserInfo: USER_INFO
		}),
		...mapActions('Auth', {
			newMode: NEW_MODE
		}),
		isError(method_name) {
			return this.loading(method_name) === 'error'
		},
		getTranslate(value) {
			let item = this.arrayOfObjectRoles.filter((item) => item.value === value);
			if (item[0]) {
				return item[0].translate;
			}
		},
		Logout() {
			this.$router.push('/');
			sessionStorage.clear();
			this.$store.commit('user/session', {session: '', accesses: ''});
		},
		async Enter(id, group, type) {
			try {
				await this.newMode({id, type})
				if (!this.isError(NEW_MODE)) {
					let routeData = this.$router.resolve({path: `/${group}`});
					window.open(routeData.href, '_blank');
				} else {
					this.$notify.warning({
						title: 'Ошибка',
						text: 'Не удалось совершить переход, попробуйте повторить позже',
					});
				}
			} catch (e) {
				this.Loading = false;
				this.$notify.error({
					title: 'Ошибка',
					text: 'Ошибка с сетью',
				});
			}
		},
	},
};
</script>
<style lang="scss">
html {
	overflow-y: auto !important;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.9);
	background-color: rgb(122, 112, 110);
}

::-webkit-scrollbar {
	width: 6px;
	background-color: #3c3c3b;
}

::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 9px 6px rgba(187, 152, 152, 0.3);
	background-color: #485caa;
}
</style>
<style lang="scss" scoped>
main {
	background: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 0%), url(~@/assets/img/background/bg.jpg);
	background-size: cover, cover;
	background-color: var(--v-secondary-base);
	background-position: 0 0, 0 0;
	background-repeat: no-repeat;
}

/////////////// SCROLLBAR
.fz-13 {
	font-size: 13px !important;
	font-weight: 400 !important;
}

.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	.h2-cont {
		margin: 0 50px 0 50px;
		border-bottom: 1px solid var(--v-fontcolor-base);
		
		h2 {
			margin-top: 40px;
			padding-bottom: 20px;
			font-weight: normal;
			font-size: 17px;
			line-height: 20px;
			text-align: center;
		}
	}
	
	.button-container {
		width: 100%;
		padding: 0;
		margin: 22px 0 0 0;
		
		.but {
			padding-left: 0;
			font-style: normal;
			font-size: 13px;
			line-height: 15px;
			width: 100%;
			display: flex;
			justify-content: flex-start;
			height: 43px;
		}
	}
}

.topbar {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 60px;
}

.v-toolbar__content {
	width: 100%;
}

.maincont {
	background-color: white;
	
	.card {
		margin-top: calc(60px - 24px);
		height: 328px;
		
		.title-container {
			height: 49px;
			background-color: var(--v-accent-base);
			
			h3 {
				line-height: 18px;
				font-size: 18px;
				font-weight: 400 !important;
			}
		}
		
		.access-container {
			overflow: auto;
			height: calc(328px - 49px);
			padding: 8px 17px 0 17px;
			.but {
				width: 100%;
				margin: 10px 0 10px 0;
				font-weight: 400 !important;
				overflow: hidden;
				font-size: 12px;
				line-height: 16px;
				text-transform: none;
			}
			
			.group-container {
				margin-top: 8px;
				padding: 0 10px 0 10px;
				width: 100%;
				border: 0.4px solid var(--v-fontcolor-base);
				
				.but {
					margin: 10px 0 10px 0;
					font-weight: 400 !important;
				}
			}
		}
	}
}

@media (min-width: 375px) {
	.maincont {
		padding: 0 20px 0 20px;
		
		.container {
			padding: 0 20px 0 20px;
			
			.collapse-button {
				margin: 5px 0 0 20px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

@media (min-width: 768px) {
	.maincont {
		.container {
			.collapse-button {
				margin: 5px 0 0 30px;
				position: fixed;
				top: 63px;
				left: 0;
			}
		}
	}
}

#custom-scroll {
	max-height: 410px;
	overflow-y: auto;
}

.el-card__body {
	overflow: auto;
}

.groupIntiti {
	padding: 0.2em;
	border: 1px solid black;
}

.headerGroup {
	margin: 0.2em 0.5em;
	font-size: 20px;
}

.space {
	margin-bottom: 0.4rem;
}

.off-canvas {
	min-height: 100vh;
}

.list-item {
	display: inline-block;
	margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
	transition: all 0.5s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
{
	opacity: 0;
	transform: translateY(30px);
}

.firstletter::first-letter {
	text-transform: uppercase;
}
</style>
